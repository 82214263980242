import axios from "axios";
import i18n from "i18next";
import numeral from "numeral";
import Swal from "sweetalert2";

import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Guest, Transaction, Loans } from "../inteface/data";

import TableFilter from "./components/datatable/TableFilter";

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const modalButton = useRef<HTMLButtonElement>(null);

  const [transactions, setTransactions] = useState<Array<Transaction>|undefined>(undefined);
  const [loans, setLoans] = useState<Array<Loans>|undefined>(undefined);
  const [profile, setProfile] = useState<Guest|undefined>(undefined);
  const [ticker, setTicker] = useState<any | undefined>(undefined);
  const [detail, setDetails] = useState<any | undefined>(undefined);
  
  const [lang, setLang] = useState<string>(i18n.language || "CN");
  const [prevhall, setPrevHall] = useState<string>("");
  const [hall, setHall] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(true);

  const transactionsColumns = [
    {
      Header: "Guest List",
      columns: [
        {
          Header: `${t("date")}`,
          accessor: "DateTimeAdded",
          filter: "text",
        },
        {
          Header: `${t("hall")}`,
          accessor: "OfficeName",
          filter: "globalFilter",
        },
        {
          Header: `${t("amount")}`,
          accessor: "TransactionAmount",
          filter: "text",
        },
        {
          Header: `${t("balance")}`,
          accessor: "Balance",
          filter: "text",
        },
        {
          Header: `${t("remarks")}`,
          accessor: "Remark",
          filter: "text",
        },
      ],
    },
  ];
  
  const loanColumns = [
    {
      Header: "Guest List",
      columns: [
        {
          Header: `${t("date")}`,
          accessor: "LoanDate",
          filter: "text",
        },
        {
          Header: `${t("hall")}`,
          accessor: "HallName",
          filter: "globalFilter",
        },
        {
          Header: `${t("account")}`,
          accessor: "AccountNumber",
          filter: "text",
        },
        {
          Header: `${t("loan_id")}`,
          accessor: "LoanNumber",
          filter: "text",
        },
        {
          Header: `${t("amount")}`,
          accessor: "LoanAmount",
          filter: "text",
        },
      ],
    },
  ];

  const GetHallList = ticker?.Hall?.map((hall: any, index: number) => (
    <option key={index} value={hall.OfficeName}>
      {hall.OfficeName}
    </option>
  ));

  const isStringObject = (string:string) => {
    try {
      JSON.parse(string);
    } catch (e) {
        return false;
    }
    return true;
  }

  const fetchDetails = async (userInfo:any) => {
    try {
      await axios
        .post(
          "https://3qlpita71b.execute-api.ap-east-1.amazonaws.com/" + process.env.REACT_APP_BUILD?.toLowerCase() + "/web-get-account-totals",
          {
            stage: {"lambdaAlias": process.env.REACT_APP_BUILD?.toLowerCase()},
            body: { 
              RID: userInfo.session_token.toString(),
              Hall: hall.toString() || userInfo.user.hall.toString(),
              AccountNumber: userInfo.user.account.toString(),
            }
          },
          {
            timeout: 0,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            if (typeof response.data === "string" && isStringObject(response.data)) {
              const result = JSON.parse(response.data)
              if(!result.hasOwnProperty("errorMessage")) {
                setTicker(result);
              } else {
                setTicker(undefined);
              }
            } else {
              setTicker(undefined);
            }
          } else {
            setTicker(undefined);
          }
        })
        .catch((error: any) => {
          setTicker(undefined);
        });
    } catch (error: any) {
      setTicker(undefined);
    }
  };

  const fetchTransactions = async (userInfo:any) => {
    try {
      await axios
        .post(
          "https://3qlpita71b.execute-api.ap-east-1.amazonaws.com/" + process.env.REACT_APP_BUILD?.toLowerCase() + "/web-customertransactions",
          {
            stage: {"lambdaAlias": process.env.REACT_APP_BUILD?.toLowerCase()},
            body: { 
              RID: userInfo.session_token.toString(),
              Hall: hall.toString() || userInfo.user.hall.toString(),
              AzFKey: userInfo.user.account.toString(),
            }
          },
          {
            timeout: 0,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            if (Array.isArray(response.data)) {
              const result: Array<Transaction> = response.data;
              setTransactions(result);
            } else {
              setTransactions([]);
            }
          } else {
            setTransactions([]);
          }
        })
        .catch((error: any) => {
          setTransactions([]);
        });
    } catch (error: any) {
      setTransactions([]);
    }
  };

  const fetchLoans = async (userInfo:any) => {
    try {
      await axios
        .post(
          "https://3qlpita71b.execute-api.ap-east-1.amazonaws.com/" + process.env.REACT_APP_BUILD?.toLowerCase() + "/web-cloans",
          {
            stage: {"lambdaAlias": process.env.REACT_APP_BUILD?.toLowerCase()},
            body: { 
              RID: userInfo.session_token.toString(),
              Hall: hall.toString() || userInfo.user.hall.toString(),
              AzFKey: userInfo.user.account.toString(),
            }
          },
          {
            timeout: 0,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            if (Array.isArray(response.data)) {
              const result: Array<Loans> = response.data;
              setLoans(result);
            } else {
              setLoans([]);
            }
          } else {
            setLoans([]);
          }
        })
        .catch((error: any) => setLoans([]));
    } catch (error: any) {
      setLoans([]);
    }
  };

  const setLanguage = (locale: string) => {
    localStorage.setItem("lang", locale);
    i18n.changeLanguage(locale);
    setLang(locale);
  };

  const onLogout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("lang");
    navigate("/login");
  };

  useEffect(() => {
    if(hall !== prevhall) {
      Swal.fire({
        title: `${t("loading")}`,
        text: `${t("loading_message")}`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  
      const userInfo = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")!)
        : undefined;
  
      (async () => {
        setLoading(true);
        await Promise.all([
          fetchDetails(userInfo),
          fetchTransactions(userInfo),
          fetchLoans(userInfo),
        ])  
        setPrevHall(hall);
        setLoading(false);
        Swal.close();
      })();
    }
  }, [hall]);

  useEffect(() => {
    let timeout: any;

    const body = document.getElementsByTagName("body")[0];
    body.setAttribute("className", "g-sidenav-show bg-gray-100");

    const userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")!)
      : undefined;

    if (userInfo) {
      setPrevHall(userInfo.user.hall);
      setHall(userInfo.user.hall);

      const expireDate: string = userInfo.expires_at.toString();
      const dateTime = expireDate.split(" ");
      const dateExpiry: Array<string> = dateTime[0].split("-");
      const timeExpiry: Array<string> = dateTime[1].split(":");
      const duration: number = new Date(
        parseInt(dateExpiry[0]),
        parseInt(dateExpiry[1]),
        parseInt(dateExpiry[2]),
        parseInt(timeExpiry[0]),
        parseInt(timeExpiry[1]),
        parseInt(timeExpiry[2])
      ).getTime();

      const expiry = duration - new Date().getTime();

      if (expiry > 0) {
        Swal.fire({
          title: `${t("loading")}`,
          text: `${t("loading_message")}`,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        timeout = setTimeout(() => {
          Swal.fire({
            icon: "warning",
            title: `${t("session_expired")}`,
            text: `${t("session_message")}`,
            showConfirmButton: false,
            timer: 3000,
            didOpen: () => {
              Swal.hideLoading();
            },
            allowOutsideClick: false,
          }).then(onLogout);
        }, expiry / 1000);
        
        (async () => {
          await Promise.all([
            fetchDetails(userInfo),
            fetchTransactions(userInfo),
            fetchLoans(userInfo),
          ])  
          setLoading(false);
          Swal.close();
        })();
      } else {
        Swal.fire({
          icon: "error",
          title: `${t("session_expired")}`,
          text: `${t("session_message")}`,
          showConfirmButton: false,
          timer: 3000,
          didOpen: () => {
            Swal.hideLoading();
          },
          allowOutsideClick: false,
        }).then(onLogout);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: `${t("error")}`,
        text: `${t("error_message")}`,
        showConfirmButton: false,
        timer: 3000,
        didOpen: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
      }).then(onLogout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const showDetails = (data: any) => {
    setDetails(data);
    modalButton?.current?.click();
  };

  return (
    <>
      <div className="main-content position-relative bg-gray-100 max-height-vh-100 h-100">
        <nav className="navbar navbar-main navbar-expand-lg bg-transparent shadow-none position-absolute px-4 w-100 z-index-2">
          <div className="container-fluid py-1">
            <div
              className="collapse navbar-collapse me-md-0 me-sm-4 mt-sm-0 mt-2 show"
              id="navbar"
            >
              <ul className="ms-md-auto navbar-nav justify-content-end">
                <li className="nav-item d-flex align-items-center">
                  <button
                    className="btn btn-link nav-link text-white font-weight-bold px-0"
                    onClick={onLogout}
                  >
                    <i className="fa fa-user me-sm-1"></i>
                    &nbsp;
                    <span className="d-sm-inline">{t("signout")}</span>
                  </button>
                  &nbsp;&nbsp;&nbsp;
                </li>

                <li className="nav-item dropdown pe-2 d-flex align-items-center">
                  <button
                    className="btn btn-link nav-link text-white p-0"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      alt="flag"
                      src={`../assets/img/icons/${lang}.svg`}
                      className="avatar avatar-sm bg-white rounded-circle mx-auto d-block border"
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-end px-2 py-3 ms-n4"
                    aria-labelledby="dropdownMenuButton"
                  > 
                    <li className="mb-2">
                      <button
                        className="btn btn-link dropdown-item border-radius-md"
                        onClick={() => setLanguage("EN")}
                      >
                        <div className="d-flex py-1">
                          <div className="my-auto">
                            <img
                              alt="english-flag"
                              src="../assets/img/icons/EN.svg"
                              className="avatar avatar-sm rounded-circle bg-white me-3 border"
                              style={{ width: "100%", height: "auto" }}
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              <span className="font-weight-bold">English</span>
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                    <li className="mb-2">
                      <button
                        className="btn btn-link dropdown-item border-radius-md"
                        onClick={() => setLanguage("ZH")}
                      >
                        <div className="d-flex py-1">
                          <div className="my-auto">
                            <img
                              alt="chinese-traditional-flag"
                              src="../assets/img/icons/ZH.svg"
                              className="avatar avatar-sm rounded-circle bg-white me-3 border"
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              中国 (Traditional)
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                    <li className="mb-2">
                      <button
                        className="btn btn-link dropdown-item border-radius-md"
                        onClick={() => setLanguage("CN")}
                      >
                        <div className="d-flex py-1">
                          <div className="my-auto">
                            <img
                              alt="chinese-simplified-flag"
                              src="../assets/img/icons/CN.svg"
                              className="avatar avatar-sm rounded-circle bg-white me-3 border"
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              中国 (Simplified)
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                    <li>
                      <button
                        className="btn btn-link dropdown-item border-radius-md"
                        onClick={() => setLanguage("KR")}
                      >
                        <div className="d-flex py-1">
                          <div className="my-auto">
                            <img
                              alt="korean-flag"
                              src="../assets/img/icons/KR.svg"
                              className="avatar avatar-sm rounded-circle bg-white me-3 border"
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              Korean
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="container-fluid mb-4">
          <div
            className="page-header min-height-150 border-radius-xl mt-4 "
            style={{
              backgroundColor: "orange",
              backgroundImage: `url(${"../assets/img/curved-images/curved5.jpg"})`,
              backgroundSize: "cover",
            }}
          >
            <span className="mask bg-gradient-warning opacity-6"></span>
          </div>
          <div className="card card-body blur shadow-blur mx-4 mt-n6 overflow-hiddenCols">
            <div className="row gx-4">
              <div className="col-auto">
                <div className="avatar avatar-xl position-relative">
                  <img
                    src="../assets/img/profile.png"
                    alt="profile_image"
                    className="w-100 border-radius-lg shadow-sm"
                  />
                </div>
              </div>
              <div className="col-auto my-auto">
                <div className="h-100">
                  <h2 className="mb-1 text-uppercase">
                    {profile?.info.AzFKey || ""}
                    {profile?.info.AzNo || ""}
                  </h2>
                  <p className="mb-1 font-weight-bold text-xs">
                    {profile?.info.OfficeName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid d-block d-sm-none mt-4 mb-4">
          <button
            type="button"
            className="btn bg-gradient-danger w-100 mb-0"
            onClick={onLogout}
          >
            {t("signout")}
          </button>
        </div>

        <div className="container-fluid">
          {ticker?.Hall && ticker?.Hall.length > 0 && (
            <div className="row text-center">
              <h6 className="text-xs mb-2 pb-0 font-weight-bolder mb-0">
                {t("hall")}
              </h6>
              <div className="col-xl-12 col-sm-12 mb-4">
                <select
                  className="form-control form-control-xl text-center"
                  id="choices-hall"
                  name="choices-hall"
                  required={true}
                  disabled={loading}
                  placeholder="Hall Name"
                  value={hall || ""}
                  onChange={(e) => setHall(e.currentTarget.value)}
                >
                  {GetHallList}
                </select>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-xl-6 col-sm-6 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-capitalize font-weight-bold">
                          {t("current_balance")}
                        </p>
                        <h5 className="font-weight-bolder mb-0">
                          {profile?.info.OfficeName.substr(
                            profile?.info.OfficeName.length - 3,
                            profile?.info.OfficeName.length
                          ) || ""}{" "}
                          &nbsp;
                          {numeral(ticker?.Balance).format("0,0.00") ||
                            "0.00"}
                        </h5>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-gradient-warning shadow text-center border-radius-md">
                        <i
                          className="fs-4 ni ni-money-coins opacity-10"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-sm-6 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-capitalize font-weight-bold">
                          {t("total_loan")}
                        </p>
                        <h5 className="font-weight-bolder mb-0">
                          {profile?.info.OfficeName.substr(
                            profile?.info.OfficeName.length - 3,
                            profile?.info.OfficeName.length
                          ) || ""}{" "}
                          &nbsp;
                          {numeral(ticker?.Loan).format("0,0.00") ||
                            "0.00"}
                        </h5>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-gradient-warning shadow text-center border-radius-md">
                        <i
                          className="fs-4 fas fa-hand-holding-usd opacity-10"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header pb-0 p-3">
                  <h6 className="mb-1">{t("transactions")}</h6>
                  <p className="text-xs mb-0 pb-0 d-sm-block d-md-none d-block d-sm-none">
                    {t("table_message")}
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="row d-sm-none d-md-block d-none d-sm-block">
                    <div className="table-responsive p-0">
                      {
                        <TableFilter
                          columns={transactionsColumns}
                          data={transactions || []}
                          hiddenCols={["AzFKey", "TransactionNumber"]}
                          filters={[]}
                          loading={false}
                        />
                      }
                    </div>
                  </div>
                  <div className="row d-sm-block d-md-none d-block d-sm-none">
                    <div className="table-responsive p-0">
                      {
                        <TableFilter
                          name={"transactions"}
                          columns={transactionsColumns}
                          data={transactions || []}
                          hiddenCols={["OfficeName", "AzFKey", "Balance"]}
                          filters={[]}
                          loading={false}
                          callback={showDetails}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="card mb-4">
                <div className="card-header pb-0 p-3">
                  <h6 className="mb-1">{t("loans")}</h6>
                  <p className="text-xs mb-0 pb-0 d-sm-block d-md-none d-block d-sm-none">
                    {t("table_message")}
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="row d-sm-none d-md-block d-none d-sm-block">
                    <div className="table-responsive p-0">
                      {
                        <TableFilter
                          columns={loanColumns}
                          data={loans || []}
                          hiddenCols={[]}
                          filters={[]}
                          loading={false}
                        />
                      }
                    </div>
                  </div>
                  <div className="row d-sm-block d-md-none d-block d-sm-none">
                    <div className="table-responsive p-0">
                      {
                        <TableFilter
                          name={"loans"}
                          columns={loanColumns}
                          data={loans || []}
                          hiddenCols={["HallName", "AccountNumber"]}
                          filters={[]}
                          loading={false}
                          callback={showDetails}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={`modal mt-4 fade`}
              id="detailsModal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="detailsModal"
              aria-hidden={detail ? false : true}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title font-weight-normal"
                      id="detailsModalLabel"
                    >
                      {t(`${detail?.type}` || "details")}
                    </h5>
                    <button
                      type="button"
                      className="btn-close text-dark"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setDetails(undefined)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {detail?.type === "transactions" && (
                      <p className="mx-4 my-4">
                        <span className="mx-2 font-weight-bolder">
                          {t("date")}:
                        </span>{" "}
                        {detail?.row.DateTimeAdded || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("hall")}:
                        </span>{" "}
                        {detail?.row.OfficeName || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("amount")}:
                        </span>{" "}
                        {numeral(detail?.row.TransactionAmount).format(
                          "0,0.00"
                        ) || ""}
                        <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("balance")}:
                        </span>{" "}
                        {numeral(detail?.row.Balance).format("0,0.00") || ""}
                        <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("remarks")}:
                        </span>{" "}
                        {detail?.row.Remark || ""} <br />
                      </p>
                    )}

                    {detail?.type === "loans" && (
                      <p className="mx-4 my-4">
                        <span className="mx-2 font-weight-bolder">
                          {t("date")}:
                        </span>{" "}
                        {detail?.row.LoanDate || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("hall")}:
                        </span>{" "}
                        {detail?.row.HallName || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("account")}:
                        </span>{" "}
                        {detail?.row.AccountNumber || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("loan_id")}:
                        </span>{" "}
                        {detail?.row.LoanNumber || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("amount")}:
                        </span>{" "}
                        {numeral(detail?.row.LoanAmount).format("0,0.00") || ""}{" "}
                        <br />
                      </p>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn bg-gradient-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              ref={modalButton}
              className="btn bg-gradient-primary"
              data-bs-toggle="modal"
              data-bs-target="#detailsModal"
              style={{ display: "none" }}
            >
              Launch demo modal
            </button>
          </div>
          <footer className="footer py-5">
            <div className="container">
              <div className="row">
              <div className="col-12 mx-auto text-center mt-1 text-xxs">
                {`Version ${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_BUILD} `}
              </div>
                <div className="col-8 mx-auto text-center mt-1">
                  <p className="mb-0 text-xs text-secondary">
                    Copyright ©{" " + new Date().getFullYear() + " "}
                    OGTING.com. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Profile;
