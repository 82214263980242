/* eslint-disable */
import React, { useRef, useState, useEffect, useCallback, ChangeEvent } from "react";

import {
  ClassAttributes,
  ReactChild,
  ReactFragment,
  ReactPortal,
  TdHTMLAttributes
} from "react";

import {
  useTable,
  useSortBy,
  useGlobalFilter,
  Column,
  Row,
  IdType,
  usePagination,
} from "react-table";

import { matchSorter } from "match-sorter";
import numeral from "numeral";


interface TableProps<T extends object> {
  name?:string,
  columns: Column<T>[];
  data: T[];
  hiddenCols: string[] | undefined;
  filters: string[];
  loading: boolean;
  callback?:Function
}

// @ts-ignore
const TableFilter = ({ name, columns, data, hiddenCols, filters, loading, callback }: TableProps<T>) => {
  // @ts-ignore
  const ourGlobalFilterFunction = useCallback(
    // @ts-ignore
    (rows: Row<T>[], ids: IdType<T>[], query: string) => {
      return matchSorter(rows, query, {
        keys: filters.map((columnName) => `values.${columnName}`),
      });
    },
    [filters]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    allColumns,
    getToggleHideAllColumnsProps,
    // @ts-ignore
    setGlobalFilter,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { pageIndex, pageSize },
    // @ts-ignore
  } = useTable<T>(
    {
      columns,
      data,
      // @ts-ignore
      initialState: { pageIndex: 0, hiddenColumns: hiddenCols },
      globalFilter: ourGlobalFilterFunction,
      show: false
    },
    useGlobalFilter,
    //useSortBy,
    usePagination
  );

  const [filter, setFilter] = useState("");
  const refs = useRef(new Array(allColumns.length));

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFilter(value);
  };
  useEffect(() => {
    setGlobalFilter(filter);
  }, [filter, setGlobalFilter]);

  return (
    <div className="table-responsive p-0">
      <div className="table align-items-center mb-0">
       <table
          className="table align-items-center mb-0"
          id="datatable-search"
          {...getTableProps()}
        >
          <thead className="thead-light">
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {index > 0 &&
                  headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(
                        //column.getSortByToggleProps()
                      )}
                      className="align-middle text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                    >
                      {column.render("Header")} &nbsp;
                    </th>
                  ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row: Row<object>, i: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(
                      (
                        cell: {
                          getCellProps: () => JSX.IntrinsicAttributes &
                            ClassAttributes<HTMLTableDataCellElement> &
                            TdHTMLAttributes<HTMLTableDataCellElement>;
                          render: (
                            arg0: string
                          ) =>
                            | boolean
                            | ReactFragment
                            | ReactChild
                            | ReactPortal
                            | null
                            | undefined;
                        },
                        index: number
                      ) => {
                        return (
                          <td
                            className="align-middle text-center text-sm"
                            onClick={(e)=>{
                              if (typeof callback == 'function') { 
                                callback({type: name, row: row.values}); 
                              }
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="align-middle text-center text-sm" colSpan={headerGroups[1].headers?.length}>
                  {loading ? "Loading..." : "No entries found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableFilter;
